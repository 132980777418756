import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { Transaction } from "../types";
import { useToast } from "../contexts/ToastContext";
import { copy } from "../utils/copy";
export default function TransactionDetails({
  transaction,
  setClose,
}: {
  transaction: Transaction;
  setClose: () => void;
}) {
  const { showToast } = useToast();

  // Destructure transaction for cleaner code
  const {
    type,
    id,
    marketValueForOne,
    network,
    transactionHash,
    asset,
    amount,
    paymentAmount,
    calculatedMarketValue,
    calculatedTradingFee,
    finalQuoteValue,
    usdAmount,
    createdAt,
    status,
    paymentInfo,
    calculatedPayout,
    calculatedProcessingFee,
    destinationAddress,
    fromAsset,
    toAsset,
  } = transaction;

  const referenceCode = id.split("-")[0];

  const isUSDDeposit =
    transaction.type === "Deposit" && transaction.asset === "USD";
  const isCryptoDeposit =
    transaction.type === "Deposit" && transaction.asset !== "USD";

  const formattedPaymentAmount = paymentAmount?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedAmount = amount?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedUsdAmount = usdAmount?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedCalculatedPayout = calculatedPayout?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedCalculatedProcessingFee =
    calculatedProcessingFee?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const formattedCalculatedTradingFee = calculatedTradingFee?.toLocaleString(
    "en-US",
    { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  );
  const formattedFinalQuoteValue = finalQuoteValue?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const formattedCalculatedMarketValue = calculatedMarketValue?.toLocaleString(
    "en-US",
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  );

  if (!transaction) return null;

  const copyText = (text: string) => {
    copy(text);
    showToast("Copied to clipboard");
  };

  const close = () => {
    setClose();
  };

  const getTitle = () => {
    switch (type) {
      case "Payment":
        if (destinationAddress) {
          return `Transfer to Wallet`;
        }
        return "Transfer to Bank Account";
      case "Trade":
        // not offramp
        if (toAsset?.toLowerCase() !== "usd") {
          return `Traded ${fromAsset} for ${toAsset}`;
        }
        return `Traded ${asset} for USD`;
      case "Deposit":
        return `Deposit`;
      default:
        return "Transaction";
    }
  };

  const renderTransactionDetails = () => {
    return (
      <>
        {type === "Deposit" && isCryptoDeposit && (
          <>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Price</label>
              <div className="w-2/3 text-right text-gray-500">
                ${marketValueForOne}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Network</label>
              <div className="w-2/3 text-right text-gray-500">{network}</div>
            </div>
            <div className="flex items-center justify-between">
              <label className="w-1/3">Transaction hash</label>
              <div className="flex-1 text-right text-gray-500">
                <span className="hidden sm:inline">
                  {transactionHash?.substring(0, 8)}...
                  {transactionHash?.slice(-8)}
                </span>
              </div>
            </div>
          </>
        )}
        {type === "Deposit" && isUSDDeposit && (
          <>
            <div className="flex items-center justify-center">
              <label className="w-1/3 font-bold">Source</label>
              <div className="w-2/3 text-right text-gray-500"></div>
            </div>
            <div className="flex items-center justify-center pl-2">
              <label className="w-1/3">Deposit method</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                {transaction?.source?.routingNumber ? "Wire" : "ACH"}
              </div>
            </div>
            {transaction?.memo && (
              <div className="flex items-center justify-center  pl-2">
                <label className="w-1/3">Memo</label>
                <div className="w-2/3 text-right text-gray-500">
                  {transaction.memo}
                </div>
              </div>
            )}
            {transaction?.source?.accountName && (
              <div className="flex items-center justify-center  pl-2">
                <label className="w-1/3">Account name</label>
                <div className="w-2/3 text-right text-gray-500">
                  {transaction.source.accountName}
                </div>
              </div>
            )}
            {transaction?.source?.accountNumber && (
              <div className="flex items-center justify-center  pl-2">
                <label className="w-1/3">Account number</label>
                <div className="w-2/3 text-right text-gray-500">
                  ****{transaction.source.accountNumber}
                </div>
              </div>
            )}
            {transaction?.source?.bankName && (
              <div className="flex items-center justify-center  pl-2">
                <label className="w-1/3">Bank name</label>
                <div className="w-2/3 text-right text-gray-500">
                  {transaction.source.bankName}
                </div>
              </div>
            )}
            {transaction?.source?.address && (
              <div className="flex items-center justify-center  pl-2">
                <label className="w-1/3">Address</label>
                <div className="w-2/3 text-right text-gray-500">
                  {transaction.source.address}
                </div>
              </div>
            )}
          </>
        )}
        {type === "Trade" && (
          <>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Source</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                {asset} Wallet
              </div>
            </div>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Amount</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                {formattedAmount} {asset}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Sale Price</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                {toAsset?.toLowerCase() === "usd"
                  ? `$${formattedCalculatedMarketValue}`
                  : `$${marketValueForOne}`}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Beam Fee</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                ${formattedCalculatedTradingFee || 0.0}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <label className="w-1/3">Net Proceeds</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                {toAsset?.toLowerCase() === "usd"
                  ? `$${formattedFinalQuoteValue}`
                  : `${formattedFinalQuoteValue} ${toAsset.toUpperCase()}`}
              </div>
            </div>
          </>
        )}
        {type === "Payment" &&
          (destinationAddress ? (
            <>
              <div className="flex items-center justify-center">
                <label className="w-1/3">To</label>
                <div
                  className="w-2/3 text-right text-gray-500 cursor-pointer"
                  onClick={() => copyText(destinationAddress)}
                >
                  {!!destinationAddress
                    ? `${destinationAddress?.substring(
                        0,
                        8,
                      )}...${destinationAddress?.slice(-8)}`
                    : ""}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <label className="w-1/3">Network</label>
                <div className="w-2/3 text-right text-gray-500 capitalize">
                  {network}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <label className="w-1/3">Beam Fee</label>
                <div className="w-2/3 text-right text-gray-500 uppercase">
                  ${formattedCalculatedProcessingFee || 0.0}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <label className="w-1/3">Transaction Hash</label>
                <div
                  className="w-2/3 text-right text-gray-500 uppercase cursor-pointer"
                  onClick={() => copyText(transactionHash)}
                >
                  {!!transactionHash
                    ? `${transactionHash?.substring(0, 8)}...
                  ${transactionHash?.slice(-8)}`
                    : ""}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center">
                <label className="w-1/3">To</label>
                <div className="w-2/3 text-right text-gray-500">
                  {paymentInfo}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <label className="w-1/3">Initial Balance</label>
                <div className="w-2/3 text-right text-gray-500 uppercase">
                  ${formattedPaymentAmount}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <label className="w-1/3">Beam Fee</label>
                <div className="w-2/3 text-right text-gray-500 uppercase">
                  ${formattedCalculatedProcessingFee || 0.0}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <label className="w-1/3">Amount Withdrawn</label>
                <div className="w-2/3 text-right text-gray-500 uppercase">
                  ${formattedCalculatedPayout}
                </div>
              </div>
            </>
          ))}
        {transaction?.imad && (
          <>
            <div className="flex items-center justify-center pl-2">
              <label className="w-1/3">IMAD number</label>
              <div className="w-2/3 text-right text-gray-500 uppercase">
                {transaction.imad}
              </div>
            </div>
          </>
        )}
        {/* Add more conditions for other transaction types if needed */}
      </>
    );
  };

  return (
    <Transition.Root show={true}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={close}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center w-full">
                    <p className="text-xl font-bold dark:text-black">
                      {getTitle()}
                    </p>
                    <div className="mt-12">
                      <p className="text-md text-gray-500">
                        {type === "Deposit" && (
                          <>
                            {formattedAmount} {asset}
                          </>
                        )}
                      </p>
                      <p className="text-3xl dark:text-black mt-2">
                        {type === "Deposit" && (
                          <>
                            $
                            {Number(formattedUsdAmount)
                              .toFixed(2)
                              .toLocaleString()}
                          </>
                        )}
                        {type === "Payment" && (
                          <>
                            {transaction.destinationAddress ? "" : "$"}
                            {formattedAmount}{" "}
                            {transaction.destinationAddress
                              ? transaction.toAsset
                              : ""}
                          </>
                        )}
                        {type === "Trade" && (
                          <>
                            {transaction.toAsset?.toLowerCase() === "usd"
                              ? `$${formattedFinalQuoteValue}`
                              : `${formattedFinalQuoteValue} ${transaction.toAsset.toUpperCase()}`}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="left-0 border-b border-gray-200 w-full mt-8"></div>
                  </div>
                </div>
                <div className="sm:flex mt-8 space-y-4 flex-col">
                  <div className="flex items-center justify-center">
                    <label className="w-1/3">Reference code</label>
                    <div
                      className="w-2/3 text-right text-gray-500 uppercase cursor-pointer"
                      onClick={() => copyText(referenceCode)}
                    >
                      {referenceCode}
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <label className="w-1/3">Date</label>
                    <div className="w-2/3 text-right text-gray-500">
                      {format(new Date(createdAt), "MMMM dd, yyyy, h:mm a zzz")}
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <label className="w-1/3">Status</label>
                    <div className="w-2/3 text-right capitalize text-gray-500">
                      {status}
                    </div>
                  </div>
                  {renderTransactionDetails()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
